import {hitGoogleTagManager} from "../../tracking/google_tag_manager/init_google_tag_manager";

const openPropertyPlan = () => {
    const eventData = {event: "gallery_projection_open"};

    hitGoogleTagManager(eventData);
};

const clickOnPropertyPlanRoom = () => {
    const eventData = {
        event: "projecttion_planner",
        projecttion_planner_action: "room_click"
    };

    hitGoogleTagManager(eventData);
};

export const propertyPlan2dTracking = {
    gtm: {
        openPropertyPlan,
        clickOnPropertyPlanRoom
    }
};

//TODO delete code below after test completed CU-8695x3fyj
export enum PlanetTestsSource {
    MODAL = "offer_modal",
    PROPERTY = "property_detail",
    OFFER = "offer_detail",
    MAP = "map_offer_preview",
    CPL = "cpl"
}

interface IPlanerABTestEvent {
    custom_event: boolean;
    source: PlanetTestsSource;
}

export const hitPlanerTestEvent = (payload: IPlanerABTestEvent) => {
    const data = {
        event: "projection2d_testab",
        ...payload
    };

    hitGoogleTagManager(data);
};

import React, {FC} from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";
import {rgba} from "polished";

import {navigationHeight} from "@pg-design/navigation";

import {isBrowser} from "../../../app/read_rp_environment_variables";
import {useHeightObserver} from "../../../hooks/use_height_observer";

interface IDesktopBlend {
    children?: React.ReactNode;
    pageHeight?: string;
    isActive: boolean;
    top?: string;
    heightDecrease?: string;
    isFullScreen?: boolean;
    className?: string;
}

// TODO: `DesktopBlend` component should be moved to separate file. I'm leaving it here to avoid issues between branches during rebranding
export const DesktopBlend: FC<IDesktopBlend> = (props) => {
    const {pageHeight, ...restProps} = props;
    const {height: pageHeightLocalState, ref} = useHeightObserver({
        isActive: !props.isFullScreen && props.isActive,
        initialHeight: 0
    });

    if (isBrowser && !ref.current) {
        ref.current = document.body;
    }

    return (
        <DesktopBlendStyled {...restProps} pageHeight={pageHeightLocalState ? pageHeightLocalState + "px" : pageHeight ?? "100vh"}>
            {props.children}
        </DesktopBlendStyled>
    );
};

export const resultsList = css`
    list-style: none;
    padding: 0;
    margin: 0;
`;

export const fadeInAnimation = (time: string) => css`
    animation: fade-in ${time} cubic-bezier(0.39, 0.575, 0.565, 1) both;

    @keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;

type IDesktopBlendStyledProps = Pick<IDesktopBlend, "isActive" | "pageHeight" | "top" | "heightDecrease" | "isFullScreen">;

const DesktopBlendStyled = styled.div<IDesktopBlendStyledProps>`
    display: none;

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        display: ${(props) => (props.isActive ? "block" : "none")};
        position: absolute;
        z-index: 1000;
        left: 0;
        width: 100%;
        background-color: ${(props) => rgba(props.theme.colors.secondary, 0.75)};
        top: calc(${(props) => (props.top ? props.top : "0")} + ${`${navigationHeight}px`}); // offset for navigation height
        height: calc(${(props) => props.pageHeight} - ${(props) => (props.heightDecrease ? props.heightDecrease : `${navigationHeight}px`)});
        ${fadeInAnimation("0.2s")};

        ${(props) =>
            props.isFullScreen &&
            css`
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            `}
    }
`;
